import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, Tags, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner } from '../components/Section'
import { LetastArticlesBgWhite, CustomTags, SectionBeforefterBg, PageTitle, PageTitleWrapper, CardImg, TraingleShape, CaseStudsyContent, TagWrapper, CaseStudyWrapper} from "../components/CaseStudyStyle"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import LetastArticles from "../components/LetastArticles"
import BgBefore from '../images/case-study-before.png'
import BgAfter from '../images/case-study-after.png'
import CaseStudyFeatures from "../components/CaseStudyFeatures"


function caseStudyPage() {
  return (
    <Layout pageName="case-study-detail">
        <SEO title="Matthews Specialty Vehicles – Greensboro, NC" description="Matthews Specialty Vehicles – Greensboro, NC" />
        <SectionBeforefterBg pt="156px" pb="100px" xpt="80px" xpb="60px" mpb="40px" bgColor="#F8F8F8" bgBefore={`url(${BgBefore})`} bgAfter={`url(${BgAfter})`}>
        <TraingleShape />
        <div className="container">
            <BreadCrumb>
                <Link to="/">Home</Link> <Link to="/case-studies">Case Studies</Link><Link to="/#">Matthews Specialty Vehicles – Greensboro, NC</Link>
            </BreadCrumb>
            <PageTitleWrapper>
                <PageTitle className="h2">Matthews Specialty Vehicles – Greensboro, NC</PageTitle>
                <Tags to="/">Building Remodel – Updated Data/Fiber Cabling</Tags>
                <Tags to="/">Network Design, Installation, & Configuration</Tags>
                <Tags to="/">Phone & Video/Teleconferencing Solutions</Tags>
                <Tags to="/">Surveillance Installation</Tags>
                <Tags to="/">Digital Signage</Tags>
            </PageTitleWrapper>
            <CaseStudyWrapper>
                <CaseStudsyContent>
                    <CardImg>
                        <StaticImage
                        src="../images/case.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                        />
                    </CardImg>
                    <p>Matthew Specialty Vehicles needed several technology upgrades for their facility in Greensboro, NC, and WYN was happy to help! We removed outdated network cabling, and then installed high-speed fiber and CAT6 infrastructure cabling for phones, data, and surveillance. We placed data racking/power management in determined locations, and installed/configured new phones & video/teleconferencing equipment. Finally, we installed surveillance NVR/cameras, and placed thermal temperature monitoring solutions at key building entrances.</p>
                </CaseStudsyContent>
            </CaseStudyWrapper>
        </div>
        </SectionBeforefterBg>
        <Section pt="0" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
            <div className="container">
                <CaseStudyWrapper>
                    <MarkdownContentWrapper>
                    <MarkdownContentWrapperInner>
                        <MarkdownContent>
                            <h2>Services Delivered</h2>
                            <TagWrapper>
                                <CustomTags>Cable Decommissioning</CustomTags>
                            </TagWrapper>
                            <p>The original building tenant had an older data, communications and power management network. This cabling turned out to be outdated in terms of both network performance and fire safety standards. In order to upgrade the new Matthews Specialty Vehicles facility, WYN performed a deinstallation of the outdated, unsafe, non-flame-retardant cabling infrastructure.</p>
                            <ul>
                                <li>Decluttered walls and ceilings to assist in ease and speed of building remodeling process.</li>
                                <li>Lowered the risk of health hazards associated with having non-flame-retardant cabling installed.</li>
                                <li>Lessened the risk of trip hazards around having cabling that was improperly installed.</li>
                                <li>Saved time by avoiding any unnecessary tracing or testing of improperly labeled cabling.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <TagWrapper>
                                <CustomTags>Installation of Plenum Fiber and Copper Data Cabling</CustomTags>
                            </TagWrapper>
                            <p>Installed up-to-date Plenum rated fiber backbones between MDFs and IDFs, and placed CAT6 data cabling throughout the building. This combination of Plenum fiber and modern copper cabling provided the necessary infrastructure for networking hardware, end-user data & phone connections, printers, surveillance cameras, digital signage, and other essential features</p>
                            <ul>
                                <li>High-speed internet fiber/cabling connections installed for designated workstation VOIP phones and access points.</li>
                                <li>Incorporated the use of a fiber fusion splicer to ensure proper connectivity.</li>
                                <li>All cables tested with a Fluke DSX-5000 Versiv Certifier (printed performance report provided).</li>
                                <li>Up-to-date cabling comes with a warranty, and includes clear mapping for simpler troubleshooting.</li>
                                <li>Each cable is clearly labeled, both at the service end and in the IDF-MDF data closets.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <TagWrapper>
                                <CustomTags>Network Equipment Installation and Configuration</CustomTags>
                            </TagWrapper>
                            <p>Multiple switches and routers were installed, including a firewall in the IDF-MDF data closets. The 128 NVR was also installed for surveillance. Each data closet includes multiple uninterrupted power sources and/or battery backups to maintain connectivity. Each switch is dedicated for a specific task, including VOIP phones, data access points, and surveillance.</p>
                            <ul>
                                <li>Installing multiple switches separated by specific applications helps to limit potential downtime.</li>
                                <li>The ability to manage the technology remotely helps to eliminate many physical service visits.</li>
                                <li>Whenever physical dispatching is necessary, clear labeling and mapping of all ports accelerate troubleshooting.</li>
                                <li>Having multiple switches allows data ports to be switched over for other essential needs whenever necessary.</li>
                                <li>Having multiple IDF data closets provides needed department separation to help maintain bandwidth speeds.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <TagWrapper>
                                <CustomTags>Installed Phone and Video/Teleconferencing Solution</CustomTags>
                            </TagWrapper>
                            <p>Hosted VOIP phone system installed along with SIP lines. With this system, the client can stay connected and be able to communicate across all of their facilities. Video/teleconferencing technologies implemented also allow the client to join a single conference in multiple locations around the building, providing for smaller gathering numbers.</p>
                            <ul>
                                <li>VOIP plans offer local and long-distance options that cost less and add value.</li>
                                <li>Easy to set up and get started – just open your account and plug in your phones!</li>
                                <li>Voice Over Internet Protocol (VoIP) systems use the internet to transmit calls, offering significant cost savings.</li>
                                <li>SIP allows business users to communicate using their computers and mobile devices over the internet.</li>
                                <li>Calls (both voice & video) between SIP users are free. No boundaries, restrictive laws, or extra charges.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <TagWrapper>
                                <CustomTags>Installed Surveillance Cameras and Thermal Temperature Monitoring Solutions</CustomTags>
                            </TagWrapper>
                            <p>Installation of 70+ cameras throughout the facility including PTZ, IR, and thermal technology. Metal shop, carpentry, and machine shop were all implemented with PTZ technology for greater safety protocols. Outdoor surveillance was arranged to provide for seamless tracking of exterior activity from one adjacent camera to the next.</p>
                            <ul>
                                <li>Provided the most technologically-advanced cameras so the client could better monitor any abnormal activity</li>
                                <li>Cameras are arranged so there are no blind spots anywhere inside or outside the facility.</li>
                                <li>Night vision cameras were utilized for outside locations with low light conditions.</li>
                                <li>This system also provides the ability to view camera activity remotely from anywhere.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <TagWrapper>
                                <CustomTags>Installed Digital Signage</CustomTags>
                            </TagWrapper>
                            <p>Digital signage was installed throughout the facility, including the demarcation of sales areas, conference rooms, advertising venues, and reception areas. These digital signs are easy and helpful for visitors to navigate, and also offer capabilities for visual progress reporting and the facilitation of business meetings with interactive whiteboard presentations</p>
                            <ul>
                                <li>This digital signage system allows the client to integrate both touchless & touchscreen technologies.</li>
                                <li>Visually-based presentations maximize both the client presenter’s time, and that of their learners</li>
                                <li>Interactive whiteboards allow presenters to make changes on the fly, maximizing presentation value. </li>
                                <li>Clients have the ability to interact around and discuss visuals or ideas presented.</li>
                                <li>Not only can content be changed on the fly in person; it can even be changed remotely.</li>
                            </ul>
                        </MarkdownContent>
                        
                        <MarkdownContent>
                            <TagWrapper>
                                <CustomTags>Installed Fire Alarm and Access Control Systems</CustomTags>
                            </TagWrapper>
                            <p>Fire alarm system and access controls installed to meet all state and local requirements. Access controls chosen by the client allow for regularly scheduled access, as well as for deeper data mining to help determine entry patterns, identify both employees and visiting guests, and more.</p>
                            <ul>
                                <li>Access controls implemented dovetail with both interior and exterior installed surveillance cameras.</li>
                                <li>Access controls enable the client to record associate or guest building entry, allowing for greater security.</li>
                                <li>Access control database allows the client to determine specific visitor identities and entry points.</li>
                                <li>Fire alarm system is compliant with all modern safety standards and expectations.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Problem Statements</h2>
                            <p>The Matthews Specialty Vehicles business demands constant communication between facilities, and requires large data bandwidth. The client also has a demonstrated need for a modern surveillance system for both loss prevention as well as to help prevent accidents. The client also needs real-time production monitoring capabilities, as well as the ability to share (and tweak) presentations on the fly.</p>
                            <ul>
                                <li>The presence of large commercial vehicles onsite makes surveillance camera placement critical.</li>
                                <li>Having extended video recording storage capabilities would enable the client to review production challenges or view specific incidents.</li>
                                <li>Improved bandwidth could help speed up production and allow for better real-time communication.</li>
                                <li>A hosted/VOIP phone system would enable the client to combine phone and computer on one line, saving money.</li>
                                <li>Updated network cabling and hardware would help to maximize bandwidth capabilities for all applications.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Wyn Approach</h2>
                            <p>WYN’s approach to serving the client’s needs included a physical site survey assessment, along with meeting in person with every single client department to discern specific technological needs and opportunities around sales, operations, manufacturing, parts, engineering, project management, facility management, shipping/receiving issues, and other designated concerns.</p>
                            <ul>
                                <li>Our approach includes meeting with each individual client department to determine specific needs.</li>
                                <li>Bringing the proper bandwidth into the building was determined to be a high-priority issue for each department.</li>
                                <li>Physical security, including camera layout and access control, was identified as another major issue.</li>
                                <li>Introducing hosted/VOIP phone service was flagged as an opportunity to improve business communications.</li>
                                <li>Ensuring all IT hardware was staged and tested prior to installation was deemed important for an easier transition.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Challenges</h2>
                            <p>Removing all outdated cabling and hardware from the facility was the first challenge. Paths were measured & evaluated to ensure cable length was ideal for IDF and MDF data closet placement. Aerial work requiring scissor lifts, bucket lifts, and drones for the placement of surveillance equipment was another challenge, which included the need to work around large commercial vehicles</p>
                            <ul>
                                <li>Surveillance camera visibility challenges were overcome by utilizing drone technology to determine ideal camera placement.</li>
                                <li>Cabling challenges were addressed by physically mapping the facility and determining where core drilling was viable.</li>
                                <li>IDF/MDF data closet placements were determined by finding central locations between respective departments.</li>
                                <li>Access control challenges were overcome by identifying each associate’s role and needed level of permissions.</li>
                                <li>Access points were placed for maximized Wi-Fi connectivity utilizing mapping software and access permission information.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Benefits</h2>
                            <p>The benefits of the services we provided for the Matthews Specialty Vehicles facility included upgraded fiber & copper cabling, better organization of IT hardware, network stabilization, greater bandwidth capabilities, faster internet speeds, telecommunications upgrade through SIP trunking and VOIP technology, and 360° surveillance coverage of both interior and exterior locations.</p>
                            <ul>
                                <li>Enabled faster data speeds to boost communication and also maximize employee productivity.</li>
                                <li>Established fluid connectivity between multiple outside facilities, allowing for easier real-time collaboration.</li>
                                <li>Installed new technology with greater capabilities, that was also easier for the client to use.</li>
                                <li>WYN remotely monitors and manages all internal technology systems, providing for faster resolution of issues.</li>
                                <li>Technology renovation added value to the business, and also increased its property value.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <CaseStudyFeatures />
                        </MarkdownContent>
                    </MarkdownContentWrapperInner>
                    </MarkdownContentWrapper>
                </CaseStudyWrapper>
            </div>
        </Section>
        <LetastArticlesBgWhite>
         <LetastArticles />
        </LetastArticlesBgWhite>
    </Layout>
  )
}
export default caseStudyPage